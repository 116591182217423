import { createFeature, createReducer, on } from '@ngrx/store';
import { Authority } from 'src/app/shared/models';
import { AuthorityActions } from './authority.actions';

export const authorityFeatureKey = 'authority';

export interface AuthorityState {
  authorities: Authority[];
  selectedAuthority: Authority | null;
  loading: boolean;
  error: any;
}

export const initialState: AuthorityState = {
  authorities: [],
  selectedAuthority: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    AuthorityActions.loadAuthorities,
    (state): AuthorityState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    AuthorityActions.loadAuthoritiesSuccess,
    (state, { authorities }): AuthorityState => ({
      ...state,
      authorities,
      loading: false,
      error: null,
    })
  ),
  on(
    AuthorityActions.loadAuthoritiesFailure,
    (state, { error }): AuthorityState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    AuthorityActions.loadAuthority,
    (state): AuthorityState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    AuthorityActions.loadAuthoritySuccess,
    (state, { authority }): AuthorityState => ({
      ...state,
      authorities: [authority],
      selectedAuthority: authority,
      loading: false,
      error: null,
    })
  ),
  on(
    AuthorityActions.loadAuthorityFailure,
    (state, { error }): AuthorityState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    AuthorityActions.addAuthority,
    (state): AuthorityState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    AuthorityActions.addAuthoritySuccess,
    (state, { authority }): AuthorityState => ({
      ...state,

      authorities: [...state.authorities, authority],

      loading: false,

      error: null,
    })
  ),
  on(
    AuthorityActions.addAuthorityFailure,
    (state, { error }): AuthorityState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    AuthorityActions.removeAuthority,
    (state): AuthorityState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    AuthorityActions.removeAuthoritySuccess,
    (state, { authorityId }): AuthorityState => ({
      ...state,
      authorities: state.authorities.filter(
        authority => authority.id !== authorityId
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    AuthorityActions.removeAuthorityFailure,
    (state, { error }): AuthorityState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    AuthorityActions.updateAuthority,
    (state): AuthorityState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    AuthorityActions.updateAuthoritySuccess,
    (state, { authority }): AuthorityState => ({
      ...state,
      authorities: state.authorities.map(item =>
        // item.id === authority.id ? authority : item
        item.id === authority.id ? { ...item, ...authority } : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    AuthorityActions.updateAuthorityFailure,
    (state, { error }): AuthorityState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const authorityFeature = createFeature({
  name: authorityFeatureKey,
  reducer,
});
